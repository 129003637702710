import { createSignal, Show } from "solid-js";
import { useStore } from "@nanostores/solid";
import { $currentCategoryOrder } from "../stores/category.ts";
import { $filterItemsWhileTyping } from "../stores/options.ts";
import { localItems, localItemsSync, remoteDB } from "../assets/js/pouchDB.js";
import { dispatchForceSyncEvent } from "../assets/js/util.js";

export default function OptionsDialog() {
	// global
	const itemOrder = useStore($currentCategoryOrder);
	const filterItemsWhileTyping = useStore($filterItemsWhileTyping);
	// local
	const [showDialog, setShowDialog] = createSignal(false);
	// refs
	let optionsModal: HTMLDialogElement;

	const updateItemOrder = ({ target }: Event) => $currentCategoryOrder.set(target.value);

	const deleteStaleArchiveItems = async () => {
		await localItemsSync();

		const results = await localItems.find({
			selector: {
				archived: true,
				weight: 0,
				// if no pic, then it's definitely a stale item
				_attachments: {
					image: {
						content_type: "image/svg+xml"
					}
				}
			},
			limit: 500,
		})


		if (results.docs.length > 0) {
			const shouldDeleteStaleDocs = confirm(`Delete ${results.docs.length} stale item/s?`);

			if (shouldDeleteStaleDocs) {
				const staleArchiveItems = results.docs.map(doc => {
					doc._deleted = true;
					return doc;
				});

				await localItems.bulkDocs(staleArchiveItems);

				setShowDialog(false);
				dispatchForceSyncEvent();
			}
		} else {
			alert("No stale items found.");
		}
	};

	return (
		<>
			<button
				type="button"
				class="button--transparent"
				onClick={() => setShowDialog(true) && optionsModal.showModal()}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16px"
					viewBox="0 0 512 512"
				>
					<path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
				</svg>
			</button>
			<Show when={showDialog()}>
				<dialog
					ref={optionsModal}
					onClick={({ target }) =>
						target === optionsModal && setShowDialog(false)
					}
					class="backdrop:bg-gray-500 backdrop:bg-opacity-80 z-50 rounded-sm text-center p-8"
				>
					<div class="flex flex-col justify-center gap-y-4">
						<span class="font-mono">v{__APP_VERSION__}</span>
						<button
							type="button"
							className="button is-primary is-light"
							onClick={() => location.reload()}
						>
							Force Reload&nbsp;
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16px"
								fill="#00947e"
								viewBox="0 0 512 512"
							>
								<path d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z" />
							</svg>
						</button>

						<button
							type="button"
							className="button is-warning is-light"
							onClick={deleteStaleArchiveItems}
						>
							Delete Old Archived Items&nbsp;
						</button>

						<label class="flex justify-center gap-x-2">
							<input
								type="checkbox"
								checked={filterItemsWhileTyping()}
								onChange={({ target }) =>
									$filterItemsWhileTyping.set(
										!filterItemsWhileTyping(),
									)
								}
							/>
							Filter Items While Typing?
						</label>

						<div class="control flex flex-col gap-y-2">
							<label class="label">Item Order</label>
							<label class="flex justify-center gap-x-2 cursor-pointer">
								<input
									type="radio"
									name="itemOrder"
									value="dateDesc"
									checked={itemOrder() === "dateDesc"}
									onChange={updateItemOrder}
								/>
								<span>New → Old</span>
							</label>
							<label class="flex justify-center gap-x-2 cursor-pointer">
								<input
									type="radio"
									name="itemOrder"
									value="dateAsc"
									checked={itemOrder() === "dateAsc"}
									onChange={updateItemOrder}
								/>
								<span>Old → New</span>
							</label>
							<label class="flex justify-center gap-x-2 cursor-pointer">
								<input
									type="radio"
									name="itemOrder"
									value="asc"
									checked={itemOrder() === "asc"}
									onChange={updateItemOrder}
								/>
								<span>A → Z</span>
							</label>
							<label class="flex justify-center gap-x-2 cursor-pointer">
								<input
									type="radio"
									name="itemOrder"
									value="desc"
									checked={itemOrder() === "desc"}
									onChange={updateItemOrder}
								/>
								<span>Z → A</span>
							</label>
						</div>
					</div>

					<div class="flex justify-around mt-4 gap-x-2">
						<button
							class="button is-danger"
							onClick={() => remoteDB.logOut().then(() => location.reload())}
						>
							Logout&nbsp;
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16px"
								fill="white"
								viewBox="0 0 512 512"
							>
								<path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
							</svg>
						</button>
						<button
							type="button"
							class="button is-success"
							onClick={() => setShowDialog(false)}
						>
							Close&nbsp;
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16px"
								fill="white"
								viewBox="0 0 384 512"
							>
								<path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
							</svg>
						</button>
					</div>
				</dialog>
			</Show>
		</>
	);
}
